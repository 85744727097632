import MenuItem from '@mui/material/MenuItem'
import React from 'react'

const OH = [
  <MenuItem key="House of Representatives" value="House of Representatives">House of Representatives</MenuItem>,
  <MenuItem key="Senate" value="Senate">Senate</MenuItem>,
  <MenuItem key="Governor" value="Governor">Governor</MenuItem>,
  <MenuItem key="AG" value="AG">Attorney-General</MenuItem>,
  <MenuItem key="State Senate" value="State Senate">State Senate</MenuItem>,
  <MenuItem key="State HOR" value="State HOR">State HOR</MenuItem>,
  <MenuItem value="State Supreme Court"> State Supreme Court </MenuItem>,
  <MenuItem value="County Prosecutor">County Prosecutor</MenuItem>,
  <MenuItem value="County Auditor">County Auditor</MenuItem>,
  <MenuItem value="County Treasurer">County Treasurer</MenuItem>,
  <MenuItem value="County Council">County Council</MenuItem>,
  <MenuItem value="County Commission">County Commission</MenuItem>,
  <MenuItem value="Court of Common Pleas General Division">Court of Common Pleas General Division</MenuItem>,
  <MenuItem value="County Engineer">County Engineer</MenuItem>,
  <MenuItem value="County Recorder">County Recorder</MenuItem>,
  <MenuItem value="County Sheriff">County Sheriff</MenuItem>,
  <MenuItem value="Clerk of Courts">Clerk of Courts</MenuItem>,
  <MenuItem value="District Court of Appeals">District Court of Appeals</MenuItem>,
  <MenuItem value="Board of Education">Board of Education</MenuItem>,
]

const PA = [
  <MenuItem value="House of Representatives">
    House of Representatives
  </MenuItem>,
  <MenuItem value="Senate">Senate</MenuItem>,
  <MenuItem value="Governor">Governor</MenuItem>,
  <MenuItem value="AG">Attorney-General</MenuItem>,
  <MenuItem value="State Senate">State Senate</MenuItem>,
  <MenuItem value="Auditor">Auditor</MenuItem>,
  <MenuItem value="Treasurer">Treasurer</MenuItem>

]

const AR = [
  <MenuItem value="House of Representatives">
    House of Representatives
  </MenuItem>,
  <MenuItem value="Senate">Senate</MenuItem>,
  <MenuItem value="Governor">Governor</MenuItem>,
  <MenuItem value="AG">Attorney-General</MenuItem>,
  <MenuItem value="State Senate">State Senate</MenuItem>,
  <MenuItem value="State HOR">State HOR</MenuItem>,
  <MenuItem value="State Supreme Court"> State Supreme Court</MenuItem>,
  <MenuItem value="Mayor">Mayor</MenuItem>,
  <MenuItem value="City Council">City Council</MenuItem>,
  <MenuItem value="County Assessor">County Assessor</MenuItem>,
  <MenuItem value="County Attorney">County Attorney</MenuItem>,
  <MenuItem value="County Recorder">County Recorder</MenuItem>,
  <MenuItem value="County Sheriff">County Sheriff</MenuItem>,
  <MenuItem value="County Treasurer">County Treasurer</MenuItem>,
  <MenuItem value="School Superintendent">School Superintendent</MenuItem>,
  <MenuItem value="Board of Supervisors">Board of Supervisors</MenuItem>,
  <MenuItem value="Constable General">Constable General</MenuItem>,
  <MenuItem value="Community College District">Community College District</MenuItem>,
  <MenuItem value="Corporation Commission">Corporation Commission</MenuItem>,
  <MenuItem value="Justice of Peace">Justice of Peace</MenuItem>,
]

const WI = [
  <MenuItem value="House of Representatives">
    House of Representatives
  </MenuItem>,
  <MenuItem value="Senate">Senate</MenuItem>,
  <MenuItem value="Governor">Governor</MenuItem>,
  <MenuItem value="AG">Attorney-General</MenuItem>,
  <MenuItem value="State Senate">State Senate</MenuItem>,
  <MenuItem value="State HOR">State HOR</MenuItem>,
  <MenuItem value="County Executive">County Executive</MenuItem>,
]

const GA = [
  <MenuItem value="House of Representatives">
    House of Representatives
  </MenuItem>,
  <MenuItem value="Senate">Senate</MenuItem>,
  <MenuItem value="Governor">Governor</MenuItem>,
  <MenuItem value="AG">Attorney-General</MenuItem>,
  <MenuItem value="State Senate">State Senate</MenuItem>,
  <MenuItem value="State HOR">State HOR</MenuItem>,
  <MenuItem value="City Council">City Council</MenuItem>,
  <MenuItem value="District Attorney">District Attorney</MenuItem>,
  <MenuItem value="County Board of Commissioners">County Board of Commissioners</MenuItem>,
  <MenuItem value="School Board">School Board</MenuItem>,
]

const NY = [
  <MenuItem value="House of Representatives">
    House of Representatives
  </MenuItem>,
  <MenuItem value="Senate">Senate</MenuItem>,
  <MenuItem value="Governor">Governor</MenuItem>,
  <MenuItem value="AG">Attorney-General</MenuItem>,
  <MenuItem value="State Senate">State Senate</MenuItem>,
  <MenuItem value="State HOR">State HOR</MenuItem>,
  <MenuItem value="District Attorney">District Attorney</MenuItem>,
  <MenuItem value="City Court">City Court</MenuItem>,
  <MenuItem value="Civil Court">Civil Court</MenuItem>,
  <MenuItem value="Surrogate&apos;s Court">Surrogate&apos;s Court</MenuItem>,
  <MenuItem value="School Board">School Board</MenuItem>,
  <MenuItem value="Mayor">Mayor</MenuItem>,
  <MenuItem value="Public Advocate">Public Advocate</MenuItem>,
  <MenuItem value="Comptroller">Comptroller</MenuItem>,
  <MenuItem value="Borough President">Borough President</MenuItem>,
  <MenuItem value="City Council">City Council</MenuItem>,
]

const candidateOfficeOptions = {
    Ohio: OH,
    Pennsylvania: PA,
    Arizona: AR,
    Wisconsin: WI,
    Georgia: GA,
    "New York": NY
}

export default candidateOfficeOptions